<template>
  <div class="container">
    <div class="version">
      <div class="back" @click="backChange()">
        <i class="el-icon-arrow-left"></i>
        <span>返回项目列表</span>
      </div>
      <div class="header_logo">
        <img src="../../assets/Project/logoIcon.png" alt="">
      </div>
    </div>
    <div class="content">
      <div class="main">
        <div class="photo">
          <div style="display:flex" @mouseenter="enter()" @click="imageChange()" @mouseleave="leave()">
            <img class="img" :src="headPortrait" alt="">
            <div class="redact" @click="imageChange()" v-show="redactState">编辑</div>
          </div>
        </div>
        <div class="information">
          <div class="name">昵称：{{ name }} </div>
          <div class="amend" @click="nameChange()">修改</div>
        </div>
        <div class="information">
          <div class="name">绑定手机号：{{ phone }} </div>
          <div class="amend" @click="phoneChange()">修改</div>
        </div>
        <div class="information">
          <div class="name">邮箱：{{ mail }} </div>
          <div class="amend">修改</div>
        </div>
        <div class="information">
          <div class="name">微信：{{ vx }} </div>
          <div class="amend">修改</div>
        </div>
        <div class="exit" @click="exitChange">退出登录</div>
      </div>
    </div>
    <div class="popup_box" v-show="maskState" @click="maskChange()"></div>
    <div class="popup" v-show="popupState">
      <div class="header">
        <div class="title">修改昵称</div>
        <div class="img" @click="closeChange()"><img src="../../assets/Project/close.png" alt=""> </div>
      </div>
      <div class="ipt_box">
        <input type="text" v-model="upName">
      </div>
      <div class="enter">
        <div class="ok" @click="nameOk()">确认</div>
        <div class="cancel" @click="cancelChange()">取消</div>
      </div>
    </div>
    <div class="popup popup1" v-show="PhoneState">
      <div class="header">
        <div class="title">验证原手机号</div>
        <div class="img" @click="closeChange1()"><img src="../../assets/Project/close.png" alt=""> </div>
      </div>
      <div class="ipt_box">
        <div class="phone">+86</div>
        <input type="text" v-model="loginAccount" placeholder="">
      </div>
      <div class="note">
        <div class="txt_box"><input type="text" v-model="verifyCode" placeholder="输入验证码" name="" id="" /></div>
        <div class="code" v-if="codeShow" @click="getCode">获取验证码</div>
        <div class="code" v-else>{{ count }}s</div>
      </div>
      <div class="enter">
        <div class="ok" @click="phoneOk()">下一步</div>
        <div class="cancel" @click="cancelChange1()">取消</div>
      </div>
    </div>
    <div class="popup popup1" v-show="updateState">
      <div class="header">
        <div class="title">修改手机号</div>
        <div class="img" @click="closeChange2()"><img src="../../assets/Project/close.png" alt=""> </div>
      </div>
      <div class="ipt_box">
        <div class="phone">+86</div>
        <input type="text" v-model="loginAccount" placeholder="请输入新的手机号">
      </div>
      <div class="note">
        <div class="txt_box"><input type="text" v-model="verifyCode" placeholder="输入验证码" name="" id="" /></div>
        <div class="code" v-if="codeShow" @click="getCode">获取验证码</div>
        <div class="code" v-else>{{ count }}s</div>
      </div>
      <div class="enter">
        <div class="ok" @click="phoneOk1()">确认</div>
        <div class="cancel" @click="cancelChange2()">取消</div>
      </div>
    </div>
    <div class="popup popup2" v-show="ImageState">
      <div class="header">
        <div class="title">修改头像</div>
        <div class="img" @click="closeChange3()"><img src="../../assets/Project/close.png" alt=""> </div>
      </div>
      <div class="UserImage">
        <input type="file" value="选择图片" ref="file" @change="fileChange($event)"
          accept="image/png, image/jpeg, image/jpg" style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0" />
        <div class="upload" @click="btnChange()" v-if="filestate">
          <img src="../../assets/Login/file.png" alt="">
          点击上传头像
        </div>
        <div class="upload" v-else>
          <img class="img" :src="headPortrait1" alt="">
        </div>
        <div class="line"></div>
        <div>
          <div class="preview">
            <img :src="headPortrait1" alt="">
          </div>
          <div class="tips">头像预览</div>
        </div>
      </div>
      <div class="again">
        <div class="anew" @click="anewFile()">重新上传</div>
        <span>*仅支持【jpg】【png】格式的图片，且大小不超过3M</span>
      </div>
      <div class="enter">
        <div class="ok" @click="imageOk()">确认</div>
        <div class="cancel" @click="cancelChange3()">取消</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
import { OssManager } from "../../threejs/network/OssManager";
import { MainObjects } from "../../threejs/common/MainObjects";
export default {
  name: "About",
  data() {
    return {
      //头像
      headPortrait: '',
      headPortrait1: '',
      name: "",
      phone: '',
      mail: "",
      vx: "",
      pmpState: true,
      maskState: false,
      popupState: false,
      PhoneState: false,
      updateState: false,
      ImageState: false,
      redactState: false,
      filestate: true,
      //账号
      loginAccount: "",
      //验证码
      verifyCode: "",
      //是否发送了验证码
      codeShow: true,
      //倒计时
      count: "",
      upName: "",
      reNum: "",

    };
  },
  created() { },
  mounted() {
    this.userData()
    this.reNum = this.getNum(20); //生成图片时 造随机数
  },
  methods: {
    async userData() {
      let res = await this.$https.userInformation1();
      let data = res.data;
      this.name = data.name;
      this.phone = data.phone;
      this.loginAccount = data.phone;
      this.headPortrait = data.image;
      this.mail = data.mail;
      this.vx = data.vx;
      if (data.mail == null) {
        this.mail = "未绑定"
      }
      if (data.vx == null) {
        this.vx = "未绑定"
      }
    },
    async logoutArgument() {
      let phone = localStorage.getItem("phone");

      const res = await this.$https.logout({
        phone: phone
      });

      if (res.success == true) {
        this.$router.push({ path: "/OfficialWebsite/HomePage/HomePageContent" });
        localStorage.clear(); //清楚缓存addMemberPage

      } else if (res.errCode == "411") {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning"
        });
        this.$router.push({ path: "/OfficialWebsite/HomePage/HomePageContent" });
      } else if (res.errCode == "412") {
        this.$message({
          message: res.errMessage,
          // type: "warning"
        });
        this.$router.push({ path: "/OfficialWebsite/HomePage/HomePageContent" });
      } else if (res.errCode == "500") {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning"
        });
      }
    },

    backChange() {
      this.$router.replace({ path: "/index/user/User" });
    },
    closeChange() {
      this.maskState = false;
      this.popupState = false;
    },
    closeChange1() {
      this.maskState = false;
      this.PhoneState = false;
    },
    closeChange2() {
      this.maskState = false;
      this.updateState = false;
    },
    closeChange3() {
      this.maskState = false;
      this.ImageState = false;
    },
    nameChange() {
      this.maskState = true;
      this.popupState = true;
    },
    phoneChange() {
      this.maskState = true;
      this.PhoneState = true;
    },
    imageChange() {
      this.maskState = true;
      this.ImageState = true;
    },
    cancelChange() {
      this.maskState = false;
      this.popupState = false;
    },
    cancelChange1() {
      this.maskState = false;
      this.PhoneState = false;
    },
    cancelChange2() {
      this.maskState = false;
      this.updateState = false;
    },
    cancelChange3() {
      this.maskState = false;
      this.ImageState = false;
    },
    maskChange() {
      this.maskState = false;
      this.popupState = false;
      this.PhoneState = false;
      this.ImageState = false;
    },
    getCode() {
      if (this.loginAccount == "") {
        this.$message({
          showClose: true,
          message: "手机号码为空",
          // type: "warning",
        });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else {
        this.codeShow = false;
        const TIME_COUNT = 60;
        this.phoneArgument();
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.codeShow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.codeShow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    async phoneArgument() {
      const res = await this.$https.getPhoneVerificationCode({
        phone: this.loginAccount,
        code: this.code,
      });
      var cotionCode = res.errCode;
      if (cotionCode == null) {
        this.$message({
          showClose: true,
          message: "获取验证码成功！",
          // type: "success",
        });
      } else if (cotionCode == 401) {
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning",
        });
        return false;
      }
    },
    nameOk() {
      this.updateName()
    },
    async updateName() {
      const res = await this.$https.updateUserName({
        name: this.upName,
      })
      if (res.success == true) {
        this.popupState = false;
        this.maskState = false;
        this.userData();
        this.$message({
          showClose: true,
          message: "昵称修改成功",
        });
        localStorage.setItem("userName", this.upName)
      }
    },
    phoneOk1() {
      this.updatePhone1()
    },
    imageOk() {
      this.updateUserImage()
    },
    async updateUserImage() {
      const res = await this.$https.updateUserImage({
        image: this.headPortrait1
      })

      if (res.success == true) {
        this.$message({
          showClose: true,
          message: "头像修改成功",
        });
        this.ImageState = false;
        this.maskState = false;
        this.userData()
        localStorage.setItem("image", this.headPortrait1)
      } else if (res.errCode == 400) {
        this.$message({
          showClose: true,
          message: "请上传头像",
        });
      }
    },
    async updateUserPhone() {
      const res = await this.$https.updateUserPhone({
        newPhone: this.loginAccount,
      })

      if (res.errCode == 400) {
        this.$message({
          showClose: true,
          message: "该手机号已绑定",
        });
      } else {
        await localStorage.setItem('token', res.data)
        localStorage.setItem("phone", this.loginAccount)
        this.$message({
          showClose: true,
          message: "手机号修改成功",
        })

        await this.userData();
        await this.getUserSig()
      }
    },
    //获取 userSing
    async getUserSig() {
      const res = await this.$https.getUserSig({

      })

      if (res.success == true) {

        localStorage.setItem('userSig', res.data)

        let userID = localStorage.getItem("phone")

        let test = localStorage.getItem("userSig")

        let _this = this
        // this.$tim.logout()
        let promise = this.$tim.logout();
        promise.then(function (imResponse) {

          _this.$tim.login({ userID: userID, userSig: res.data });
        }).catch(function (imError) {
          // console.warn('logout error:', imError);
        });


      }
    },






    phoneOk() {
      this.updatePhone();
    },
    async updatePhone() {
      const res = await this.$https.codeEstimate({
        phone: this.loginAccount,
        code: this.verifyCode
      })

      if (res.success == true) {
        this.updateState = true;
        this.PhoneState = false;
        this.loginAccount = '';
        this.verifyCode = '';
        this.codeShow = true;
        this.count = 60;
      } else if (res.errCode == 402) {
        this.$message({
          showClose: true,
          message: "验证码已过期,请重新获取",
        });
      } else if (res.errCode == 401) {
        this.$message({
          showClose: true,
          message: "验证码错误,请重新输入",
        });
      }
    },
    async updatePhone1() {
      const res = await this.$https.codeEstimate({
        phone: this.loginAccount,
        code: this.verifyCode
      })
      if (res.errCode == 402) {
        this.$message({
          showClose: true,
          message: "验证码已过期,请重新获取",
        });
      } else if (res.errCode == 401) {
        this.$message({
          showClose: true,
          message: "验证码错误,请重新输入",
        });
      } else {
        this.updateUserPhone()
        this.maskState = false;
        this.updateState = false;
      }
    },
    exitChange() {
      this.logoutArgument();
      this.$message({
        // type: "info",
        message: "已退出"
      });
    },
    enter() {
      this.redactState = true;
    },
    leave() {
      this.redactState = false;
    },
    fileChange(e) {
      // let https =  "http://file.scene-space.com/";
      var _this = this;
      let https = MainObjects.Network.m_http;
      const files = e.target.files
      let data = new OssManager(MainObjects.Network.m_bucketName);
      let url = data.getUserFloder(this.reNum)
      
      function uploadFinish(list) {
        let urls = https + url + files[0].name;
        _this.headPortrait1 = urls;
        _this.filestate = false;
      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0], url)
    },
    getNum(num) {
      var random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
        Math.pow(10, num - 1)
      );
      return random;
    },
    btnChange() {
      this.$refs.file.click()
    },
    anewFile() {
      this.$refs.file.click()
    }
  },
  filters: {
  }
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
}

.version {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #fff;
  font-size: 12px;
  display: flex;
  border-bottom: 2px solid #eff3f4;
}

.version .back {
  font-weight: bold;
  margin-left: 30px;
  cursor: pointer;
}

.version .back i {
  color: #000;
  font-weight: bold;
  margin-right: 3px;
}

.version .header_logo {
  width: 60px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
}

.version .header_logo img {
  width: 60px;
  height: 30px;
}

.content {
  width: 100%;
  height: calc(100% - 42px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content .main {
  width: 60%;
  height: 70%;
  /* border: 1px solid #c33; */
}

.main .photo {
  width: 90%;
  margin: auto;
  height: 80px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.main .photo .img {
  width: 60px;
  height: 60px;
  cursor: pointer;
  border-radius: 50%;
}

.photo .redact {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #212331;
  opacity: 0.6;
  line-height: 60px;
  text-align: center;
  color: #fff;
  position: absolute;
}

.main .information {
  width: 90%;
  margin: auto;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EFF3F4;
  font-size: 14px;
  justify-content: space-between;
}

.main .information .name {
  font-weight: bold;
}

.main .information .amend {
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 3px 0px rgba(33, 35, 49, 0.1000);
  border-radius: 20px;
  cursor: pointer;
  color: #666666;
}

.main .exit {
  font-size: 14px;
  color: #666666;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 50px auto;
  cursor: pointer;
}

.popup_box {
  width: 100%;
  height: 100%;
  background: url(../../assets/Project/mask.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.popup {
  width: 320px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(33, 35, 49, 0.1000);
}

.popup1 {
  width: 380px;
  height: 200px;
}

.popup .header {
  width: 90%;
  height: 40px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;

}

.popup .header .img img {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.popup .ipt_box {
  width: 90%;
  height: 35px;
  background: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(33, 35, 49, 0.1000);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
}

.popup .ipt_box input {
  width: 95%;
  height: 35px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 15px;
  color: #666666;
}

.popup .enter {
  width: 90%;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.popup .enter .ok {
  width: 120px;
  height: 30px;
  line-height: 30px;
  box-shadow: 0px 0px 3px 0px rgba(33, 35, 49, 0.1000);
  border-radius: 8px;
  background: #0560FD;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}

.popup .enter .cancel {
  width: 120px;
  height: 30px;
  line-height: 30px;
  box-shadow: 0px 0px 3px 0px rgba(33, 35, 49, 0.1000);
  border-radius: 8px;
  background: #FFFFFF;
  text-align: center;
  color: #212331;
  cursor: pointer;
  font-size: 12px;
}

.popup1 .phone {
  width: 35px;
  color: #333333;
  font-size: 14px;
  border-right: 1px solid #EFF3F4;
}

.popup1 .note {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-top: 10px;
}

.popup1 .ipt_box input {
  width: 80%;
  height: 35px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 15px;
  color: #666666;
}

.popup1 .note .txt_box {
  width: 60%;
  height: 35px;
  background: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(33, 35, 49, 0.1000);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup1 .note .txt_box input {
  width: 90%;
  height: 35px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 15px;
  color: #666666;
}

.popup1 .note .code {
  width: 35%;
  height: 35px;
  background: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(33, 35, 49, 0.1000);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  font-size: 12px;
  cursor: pointer;
}

.popup2 {
  width: 400px;
  height: 320px;
}

.popup2 .UserImage {
  width: 95%;
  height: 180px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.popup2 .UserImage .upload {
  width: 160px;
  height: 160px;
  background: #EFF3F4;
  border: 1px dotted #999999;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #0560FD;
  cursor: pointer;
  flex-direction: column;
  font-size: 10px;
}

.popup2 .UserImage .upload img {
  width: 30px;
  height: 25px;
  /* margin-bottom: 5px; */
}

.popup2 .UserImage .upload .img {
  width: 100%;
  height: 100%;
}

.popup2 .UserImage .preview {
  width: 120px;
  height: 120px;
  background: #EEF0EC;
  opacity: 0.8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup2 .UserImage .preview img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.popup2 .UserImage .line {
  height: 100%;
  width: 1px;
  background: #EFF3F4;
}

.popup2 .UserImage .tips {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  margin-top: 10px;
}

.popup2 .again {
  width: 95%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px auto;
}

.popup2 .again .anew {
  width: 70px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  box-shadow: 0px 0px 3px 0px rgba(33, 35, 49, 0.1000);
  font-size: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #666666;
}

.popup2 .again span {
  font-size: 10px;
  color: #999999;
}

img[src=""],
img:not([src]) {
  opacity: 0;
}
</style>

 

